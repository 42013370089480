export const homePageHerosGerman = {
  bio: {
    text:
      `Die in Australien geborene Sopranistin Sharon Kempton hat einen Bachelor of Music Performance
  and Music Therapy mit Auszeichnung und einen Master of Music Performance an der University of
  Melbourne unter der Leitung von Kevin Casey...`,
    button: `Siehe Bio`,
  },
  awards: {
    text: `First Prize Winner, German Australian Opera Grant
  First Prize Winner, German Operatic Award.`,
    button: "Siehe Auszeichnungen",
  },
  reviews: {
    text: `Sharon Kempton verfügt über eine
    angenehm geschmeidig einschwingende Stimme, die sie technisch perfekt führt. So ließ sie die
    wehmütigen Zwischentöne in die Arie der Gräfin aus Figaros Hochzeit subtil deutlich werden...`,
    button: `Siehe Presse`,
  },
  upcoming: {
    text: `Siehe Sharons bevorstehende Ausstellungen..`,
    button: `Siehe Shows`,
  },
};
