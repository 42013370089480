/** @jsxRuntime classic */
/** @jsx jsx */
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

import { css, jsx } from "@emotion/react";

import Home from "./pages/Home";
import Nav from "./comps/nav";
import Child from "./routes/Child";
import Footer from "./comps/footer/index";
import { LanguageContext } from "./store";
import useInit from "./hooks/useInit";

function App() {
  const [state, dispatch] = useInit();

  const appStyles = css`
    position: relative;
    min-height: 100vh;

    width: 100%;

    background: white;
  `;

  return (
    <div css={appStyles}>
      <Router>
        <LanguageContext.Provider value={{ state, dispatch }}>
          <Nav />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/:id">
              <Child />
            </Route>
          </Switch>
          <Footer />
        </LanguageContext.Provider>
      </Router>
    </div>
  );
}

export default App;
