import React from "react";
import '../styles/Calendar.css'


const dates = [ 
  {
    date: '29.09.23, 19:30 uhr',
    title: 'Lady Macbeth von Mzensk',
    role: 'Zwangsarbeiterin',
    location: "Hessisches Staatstheater Wiesbaden"
  },
  {
    date: '22.10.23, 18:00 uhr',
    title: 'Lady Macbeth von Mzensk',
    role: 'Zwangsarbeiterin',
    location: "Hessisches Staatstheater Wiesbaden"
  },
  {
    date: '01.11.23, 19:30 uhr',
    title: 'Lady Macbeth von Mzensk',
    role: 'Zwangsarbeiterin',
    location: "Hessisches Staatstheater Wiesbaden"
  },
  {
    date: '10.11.23, 19:30 uhr',
    title: 'Lady Macbeth von Mzensk',
    role: 'Zwangsarbeiterin',
    location: "Hessisches Staatstheater Wiesbaden"
  },
  {
    date: '21.10.23, 19:30 uhr',
    title: 'Follies',
    role: 'Heidi Schiller',
    location: "Hessisches Staatstheater Wiesbaden"
  },
  {
    date: '27.10.23, 19:30 uhr',
    title: 'Follies',
    role: 'Heidi Schiller',
    location: "Hessisches Staatstheater Wiesbaden"
  },
  {
    date: '09.11.23, 19:30 uhr',
    title: 'Follies',
    role: 'Heidi Schiller',
    location: "Hessisches Staatstheater Wiesbaden"
  },
  {
    date: '12.11.23, 19:30 uhr',
    title: 'Follies',
    role: 'Heidi Schiller',
    location: "Hessisches Staatstheater Wiesbaden"
  },
  {
    date: '15.11.23, 19:30 uhr',
    title: 'Follies',
    role: 'Heidi Schiller',
    location: "Hessisches Staatstheater Wiesbaden"
  },
  {
    date: '19.11.23, 16:00 uhr',
    title: 'Follies',
    role: 'Heidi Schiller',
    location: "Hessisches Staatstheater Wiesbaden"
  },
  {
    date: '09.12.23, 19:30 uhr',
    title: 'Follies',
    role: 'Heidi Schiller',
    location: "Hessisches Staatstheater Wiesbaden"
  },
  {
    date: '31.12.23, 16:00 uhr',
    title: 'Follies',
    role: 'Heidi Schiller',
    location: "Hessisches Staatstheater Wiesbaden"
  },
  {
    date: '31.12.23, 19:30 uhr',
    title: 'Follies',
    role: 'Heidi Schiller',
    location: "Hessisches Staatstheater Wiesbaden"
  },
  {
    date: '19.01.24, 19:30 uhr',
    title: 'Follies',
    role: 'Heidi Schiller',
    location: "Hessisches Staatstheater Wiesbaden"
  },
  {
    date: '10.02.24, 19:30 uhr',
    title: 'Follies',
    role: 'Heidi Schiller',
    location: "Hessisches Staatstheater Wiesbaden"
  },
  {
    date: '08.03.24, 19:30 uhr',
    title: 'Follies',
    role: 'Heidi Schiller',
    location: "Hessisches Staatstheater Wiesbaden"
  },
  {
    date: '21.03.24, 19:30 uhr',
    title: 'Follies',
    role: 'Heidi Schiller',
    location: "Hessisches Staatstheater Wiesbaden"
  },
  {
    date: '08.06.24, 19:30 uhr',
    title: 'Follies',
    role: 'Heidi Schiller',
    location: "Hessisches Staatstheater Wiesbaden"
  },
  {
    date: '27.06.24, 19:30 uhr',
    title: 'Follies',
    role: 'Heidi Schiller',
    location: "Hessisches Staatstheater Wiesbaden"
  },
  {
    date: '04.02.24, 19:30 uhr',
    title: 'Die Walkuere',
    role: 'Gerhilde',
    location: "Hessisches Staatstheater Wiesbaden"
  },
  {
    date: '29.03.24, 19:30 uhr',
    title: 'Die Walkuere',
    role: 'Gerhilde',
    location: "Hessisches Staatstheater Wiesbaden"
  },
  {
    date: '26.06.24, 19:30 uhr',
    title: 'Die Walkuere',
    role: 'Gerhilde',
    location: "Hessisches Staatstheater Wiesbaden"
  },
];
export default function Calendar() {
  return <div className="Calendar">
    <h1>Calendar</h1>
    <section className="Calendar-content">
      {
        dates.map(
          (data, i) => <Item {...data} key={i} />
        )
      }
    </section>
  </div>
}

function Item({ title, date, location, role, address }) {
  return (
    <div className="Calendar-item">
      <h3 className="Calendar-item-title">{title}</h3>
      <p><strong>Datum:</strong> {date}</p>
      <p><strong>Veranstaltungsort:</strong> {location}</p>
      {
        role ?
          <p><strong>Partie:</strong> {role}</p> : null
      }
      {
        address ?
          <p><strong>Anschrift:</strong> {address}</p> : null
      }
    </div>);
}
