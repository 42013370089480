import { createContext } from "react";

import { homePageHerosEnglish } from "./utils/englishText";
import { homePageHerosGerman } from "./utils/germanText";
import { englishReviews, germanReviews } from "./utils/reviews";

export const German = {
  intro:
    `Die preisgekrönte australische Sopranistin Sharon Kempton tritt weltweit in Opern, Liederabenden
    und Konzerten auf.  Sie ist gerade als Die Erste Dame in Die Zauberflöte, Gertrude in Hänsel und
    Gretel und als Jungfer Marianna Leitmetzerin in Der Rosenkavalier am Hessischen
    Staatstheater aufgetreten und wird demnächst ihr Rollendebüt als Aufseherin in Elektra geben.
    Im weiteren Verlauf der Saison wird sie wieder als Gräfin de La Roche in Die Soldaten an der
    Kölner Oper auftreten.`,
  biography: [
    `Die in Australien geborene Sopranistin Sharon Kempton hat einen Bachelor of Music Performance
  and Music Therapy mit Auszeichnung und einen Master of Music Performance an der University of
  Melbourne unter der Leitung von Kevin Casey, Bettine MacCaughan OAM und der angesehenen
  Merlyn Quaife OAM absolviert. Sharon wurde mit vielen angesehenen Preisen ausgezeichnet, u.a.
  mit dem Deutschen Opernpreis (2001), wobei sie ihre europäische Karriere als Mitglied des Kölner
  Opernstudios begann, gefolgt von einem elfjährigen Engagement als Solistin am Hessischen
  Staatstheater in Wiesbaden.`,
    `Sharon ist mit dem Aalto Musiktheater Essen, der Oper Köln, dem Staatstheater Wiesbaden, dem
  Staatstheater Braunschweig, dem Staatstheater Kassel, dem Theater Ulm, dem Nationaltheater
  Mannheim, dem Theater Osnabrück und dem Saarländischen Staatstheater in Saarbrücken
  aufgetreten.`,
    `Als Ensemblemitglied des Staatstheaters Wiesbaden von 2003 bis 2014 spielte und debütierte
  Sharon vierzig Opernrollen. Als Sängerin von immenser Vielseitigkeit wurde sie von der Kritik für
  ihren warmen, stimmlichen Klangreichtum und ihre überzeugende Bühnenpräsenz gelobt. Ihr
  umfangreiches Repertoire umfasst die Gattungen der Oper des Frühbarock mit Partien wie
  Giunone (LaCallisto), Poppea (L'incoronazione di Poppea), Euridice (Orfeo ed Euridice), Elmira
  (Croesus), Armide (Armide) und Kleopatra (Julius Caesar) - für die sie 2007 von der Opernwelt,
  Deutschland, zur Sängerin des Jahres gekürt wurde - bis hin zur Klassik, Romantik und zu Werken
  der modernen Meister.`,
    `In Wiesbaden hatte Sharon einen durchschlagenden Erfolg mit vielen der schönsten Mozart-
  Rollen, die für Sopran komponiert wurden, darunter Pamina (Die Zauberföte), Ilia (Idomeneo),
  Fiordiligi (Cosi fan tutte), Gräfin Almaviva (Le nozze di Figaro), Donna Elvira (Don Giovanni)
  und in jüngster Zeit Die Erste Dame (Die Zaubeflöte).`,
    ` Ihre beeindruckende Liste des Opernrepertoires jenseits des Frühbarock und der Opernwerke
  Mozarts umfasst Peri in Schumanns Paradies und die Peri, Marzelline (Fidelio), Micaëla
  (Carmen), Musetta (La Boheme), Antonia (Hoffmanns Erzählungen), Gretel (Hänsel und Gretel),
  Norina (Don Pasquale), Rosina (Der Barbier von Sevilla), Marie (Die verkaufte Braut), Alice Ford
  (Falstaff), Gertrude (Hänsel und Gretel), Jungfer Marianna Leitmetzerin (Der Rosenkavalier),
  Gerhilde (Die Walküre) und Wellgunde in Das Rheingold und die Göttterdämmerung. Und
  insbesondere Elisabeth in Tannhäuser von Wagner.`,
    `Weitere Rollen sind Axinja (Lady Macbeth von Mzensk), Najade (Ariadne auf Naxos), Ninetta
  (Die Liebe der drei Orangen) und die vierte Magd in Elektra.
  In der Operette hat Sharon auch die Rollen der Valencienne in Die lustige Witwe, Julia De Weert
  in Der Vetter aus Dingsda, Christel in Der Vogelhändler und Rosalinde in Die Fledermaus
  gespielt.`,
    `Sharon wurde für ihre Leistung als Elisabeth Zimmer in der Uraufführung der Elegie für junge
  Liebende von Hans Werner Henze in der Regie von Dietrich Hilsdorf bei den Internationalen
  Maifestspielen 2014 in Wiesbaden gefeiert.
  Sharon hat die äußerst anspruchsvolle Rolle der Gräfin de La Roche im 'Meisterwerk' Die
  Soldaten von Bernd Alois Zimmermann für das Staatstheater Wiesbaden, das Staatstheater
  Nürnberg und die Oper Köln gespielt.`,
    `Als Oratorienkünstlerin reicht Sharons Repertoire von den frühen Werken Händels, Bachs, Haydns
  und Mozarts bis zu den neueren Werken von Holst, Poulenc und Tippet. Sie hat Oratorienwerke in
  ganz Deutschland aufgeführt, darunter Missa Solemnis (Liszt) mit dem Chor und Orchester
  Collegium Noricum, Nürnberg, Manfred (Schumann) mit dem Frankfurter Museumsorchester,
  Frankfurter Alte Oper, Esther (Händel) mit der Kantorei der Bergkirche und Karlsruher
  Barockorchester unter Christian Pfeifer und Paulus (Mendelssohn) mit dem Hessischen
  Staatsorchester Wiesbaden. Zuletzt führte sie das Magnificat in D-Dur von Bach unter der
  Leitung von Hans Kielblock auf.`,
  ],

  reviewsData: germanReviews,
  home: "Home",
  gallery: "Galerie",
  bio: "Biografie",
  reviews: "Presse",
  contact: "Kontakt",
  calendar: "Kalendar",
  isEnglish: false,
  userLang: "German",
  awards: "Auszeichnungen",
  footer: {
    reviews: "Presse",
    career: "Leistungs-Coaching",
    awards: "Auszeichnungen",
    bio: "Biografie",
    contact: "Kontakt",
  },
  homeHeros: homePageHerosGerman,
};

export const English = {
  intro: `Award winning Australian soprano Sharon Kempton
performs worldwide in opera, recitals and concerts. She has just appeared as the First Lady in the
Magic Flute, Gertrude in Hänsel und Gretel, and as Jungfer Marianna Leitmetzerin in Der
Rosenkavalier for the Hessen State Theatre and is soon to make her role debut as the Aufseherin in
Elektra. Later this season she will once again appear as Gräfin de La Roche in Die Soldaten for the Kölner Oper.`,
  biography: [
    `Australian born soprano Sharon Kempton, completed
  a Bachelor of Music Performance and Music Therapy
  with honours and Master of Music Performance at the
  University of Melbourne, under the tuition of Kevin
  Casey, Bettine MacCaughan OAM and the distinguished
  Merlyn Quaife OAM. Sharon has been the recipient of
  many prestigious prizes including the German Operatic
  Award (2001), embarking on her European career, as a
  member of the Cologne Opera Studio, Germany,
  followed by an eleven year engagement as soloist at the
  Hessen State Theater in Wiesbaden.`,
    `Sharon has performed with Aalto Musiktheater Essen,
  Köln Oper, Staatstheater Wiesbaden, Staatstheater
  Braunschweig, Staatstheater Kassel, Theater Ulm,
  Nationaltheater Mannheim, Theater Osnabrück and
  Saarländisches Staatstheater, in Saarbrücken.`,
    `As an ensemble member of Staatstheater Wiesbaden
  from 2003 until 2014, Sharon performed and debuted
  forty operatic roles. A singer of immense versatility, she
  has received critical acclaim for her warm, richness of
  vocal tone and her compelling stage presence. Her
  extensive repertoire encompasses the genres of early
  Baroque Opera, performing roles such as Giunone
  (LaCallisto), Poppea (L’incoronazione di Poppea),
  Euridice (Orfeo ed Euridice), Elmira (Croesus), Armide
  (Armide) and Cleopatra (Julius Caesar) – for which she
  was nominated ’Singer of the Year’ in 2007 by
  Opernwelt, Germany; through to Classical, Romantic
  and works of the modern Masters.`,
    `In Wiesbaden, Sharon has had resounding success with
  many of the most beautiful Mozart roles composed for
  Soprano, including Pamina (Die Zauberföte), Ilia
  (Idomeneo), Fiordiligi (Cosi fan tutte), Countess
  Almaviva (Le nozze di Figaro), Donna Elvira (Don
  Giovanni) and more recently The First Lady (Die
  Zaubeflöte).`,
    `Her impressive list of operatic repertoire beyond early
  Baroque and the operatic works of Mozart, include Peri
  in Schumann’s Paradies und die Peri, Marzelline
  (Fidelio), Micaëla (Carmen), Musetta (La Boheme),
  Antonia (The Tales of Hoffmann), Gretel (Hänsel und
  Gretel), Norina (Don Pasquale), Rosina (The Barber of
  Seville), Marie (The Bartered Bride), Alice Ford
  (Falstaff), Gertrude (Hänsel und Gretel), Jungfer
  Marianna Leitmetzerin (Der Rosenkavalier) Gerhilde
  (Die Walküre) and Wellgunde in Das
  Rheingold and Göttterdämmerung. And notably
  Elisabeth in Tannhäuser by Wagner.
  Further roles include Axinja (Lady Macbeth von
  Mzensk), Najade (Ariadne auf Naxos), Ninetta (The
  Love of Three Oranges), and Fourth Maid in Elektra.
  In Operetta, Sharon has also performed the
  roles Valencienne in The Merry Widow, Julia De
  Weert in Der Vetter aus Dingsda, Christel in Der
  Vogelhändler and Rosalinde in Die Fledermaus.`,
    `
  Sharon was acclaimed for her performance as Elizabeth
  Zimmer in the 2014 Wiesbaden International May
  Festival premiere of Elegie für junge Liebende by Hans
  Werner Henze, directed by Dietrich Hilsdorf.
  Sharon has performed the extremely demanding role of
  Gräfin de La Roche in the ‘Meisterwerk’ Die Soldaten by
  Bernd Alois Zimmermann for Staatstheater Wiesbaden,
  Staatstheater Nürnberg and Oper Köln.`,
    `As an oratorio artist Sharon’s repertoire ranges from the
  early works of Händel, Bach, Haydn and Mozart to the
  more recent works of Holst, Poulenc and Tippet. She has
  performed oratorio works throughout Germany
  including Missa Solemnis (Liszt) with the Chor und
  Orchester Collegium Noricum,
  Nuernberg, Manfred (Schumann) with the Frankfurter
  Museums Orchester, Frankfurt Alte
  Oper, Esther (Händel) with Kantorei der
  Bergkirche and Karlsruher Barockorchester conducted
  by Christian Pfeifer and Paulus (Mendelssohn) with
  the Hessisches Staatsorchester Wiesbaden. Most
  recently she performed the Bach’s Magnificat in D
  major conducted by Hans Kielblock.`,
  ],
  reviewsData: englishReviews,
  home: "Home",
  gallery: "Gallery",
  bio: "Biography",
  reviews: "Reviews",
  contact: "Contact",
  calendar: "Calendar",
  isEnglish: true,
  userLang: "English",
  awards: "Awards",
  footer: {
    reviews: "Reviews",
    career: "Performance Coaching",
    awards: "Awards",
    bio: "Biography",
    contact: "Contact",
  },
  homeHeros: homePageHerosEnglish,
};
export function reducer(state, action) {
  switch (action.type) {
    case "English":
      return { ...state, ...English };
    case "German":
      return { ...state, ...German };
    default:
      return state;
  }
}

export const LanguageContext = createContext(null);
