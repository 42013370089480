/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Suspense, useState } from "react";

import { css, jsx } from "@emotion/react";
// grid template columns defines the columns
// the rows will be defined automatically and will have an auto height
import { DownArrow } from "../assets/svgs";

import Carousel from "../comps/gallery/Carousel";
const GalleryPhotos = React.lazy(() =>
  import("../comps/gallery/GalleryPhotos")
);

function Gallery() {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [clicked, setClicked] = useState(false);
  const [id, setId] = useState(null);
  function handleClick(id) {
    setClicked((b) => !b);
    setId(id);
    getBodyClass();
  }
  function getBodyClass() {
    document.body.classList.toggle("test");
  }

  const HeaderStyles = css`
    height: 85vh;

    display: flex;
    justify-content: center;
    align-items: center;

    h3:hover ~ svg {
      transform: translateY(300%);
      transition: transform 0.4s ease-in-out;
    }
    h3:hover {
      transition: transform 0.4s ease-in-out;
      transform: perspective(100px) translateZ(-5px);
    }
    svg {
      display: block;
      transition: transform 0.4s ease-in-out;
      margin: auto;
      animation: spin 0.4s ease-in-out;
      opacity: 1;
      @keyframes spin {
        from {
          opacity: 0;
          transform: rotate(1turn);
        }
      }
    }
  `;

  const contentWrap = css`
    display: flex;
    flex-direction: column;
    text-align: center;
  `;
  const TitleStyles = css`
    font-size: 7vmin;
    font-weight: 700;
    border: 2px solid;
    padding: 0.3em 1.3em;
    margin: 0 0 0.2em 0;
    text-transform: uppercase;
    transform: perspective(100px) translateZ(0px);
    transition: transform 0.4s ease-in-out;
    span {
      font-weight: 300;
      text-transform: uppercase;
    }
  `;
  const mainStyles = css`
    background: white;
  `;

  const footerSpace = css`
    height: 300px;
    width: 300px;
  `;
  const props = { remove: () => setClicked((t) => !t), id, getBodyClass };
  return (
    <>
      {clicked && <Carousel {...props} />}
      <main css={mainStyles}>
        <header css={HeaderStyles}>
          <div css={contentWrap}>
            <h3 css={TitleStyles}>
              Photo <span>Gallery</span>
            </h3>
            <DownArrow className={"ArrowSvg"} />
          </div>
        </header>
        <Suspense fallback={<div>Loading...</div>}>
          {" "}
          <GalleryPhotos handleClick={handleClick} />
        </Suspense>

        <div css={footerSpace}></div>
      </main>
    </>
  );
}

export default Gallery;
