import img1 from "../assets/2.jpg";
import img2 from "../assets/3.jpg";
import img3 from "../assets/17.jpg";
import img4 from "../assets/15.JPG";
import img5 from "../assets/16.JPG";
import img6 from "../assets/18.jpg";
import img7 from "../assets/1.jpg";
import img8 from "../assets/19.jpg";
import img9 from "../assets/4.jpg";
// Contact
import Cover from "./contact.jpg";
import Entrance from "./entrance.JPG";
import BioPic from "./15.JPG";
import awardPic from "./awards.jpg";
import circle from "./circle.png";
import contactPic from "./sharon.jpg";
import performancePic from "./7.jpg";
import SharonHomePic from "./sharon_home.jpg";
import RedDress from "./red_dress.jpeg";
import WhiteDress from "./white_dress.jpg";
export const StagePhoto = {
  img: Cover,
  alt:
    "A line of female opera singers standing dramitically on the stage wearing war-time pilot clothing",
};
export const EntrancePhoto = {
  img: Entrance,
  alt:
    `A female opera singer shoulders a man to safety whilst standing on a stage covered in dramatic lighting`,
};
const images = [
  { img: img1, alt: "test", id: 0 },
  { img: img2, alt: "test", id: 1 },
  { img: img3, alt: "test", id: 2 },
  { img: img6, alt: "test", id: 3 },
  { img: img5, alt: "test", id: 4 },
  { img: img4, alt: "test", id: 5 },
  { img: img7, alt: "test", id: 6 },
  { img: img8, alt: "test", id: 7 },
  { img: img9, alt: "test", id: 8 },
];
export default images;
export const slides = {
  0: img1,
  1: img2,
  2: img3,
  3: img6,
  4: img5,
  5: img4,
  6: img7,
  7: img8,
  8: img9,
};
export const BioPicture = { img: BioPic, alt: "Sharon Kempton" };
export const awardsPicData = { img: awardPic, alt: "Sharon on stage" };
export const ReviewPicData = {
  img: Entrance,
  alt: "On stage, a man leans on Sharon Kempton's shoulder for support",
};
export const upcomingPerformances = {
  img: performancePic,
  alt: "Sharon Kempton embracing a man on stage ",
};
export const circlePhoto = { img: circle, alt: "A Headshot photo of Sharon" };
export const contactPicture = { img: contactPic, alt: "Sharon Kempton" };
export const HomeHero = {
  img: SharonHomePic,
  alt:
    "Sharon Kempton, wearing a bright green jacket whilst standing in front of a colorful wall. ",
};
export const whiteDress = {
  img: WhiteDress,
  alt: "A portrait of Sharon in a white dress",
};
export const redDress = {
  img: RedDress,
  alt: "A portrait of Sharon in a red dress",
};
