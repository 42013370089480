/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useContext, useRef, useState } from "react";

import { css, jsx } from "@emotion/react";
import { LanguageContext } from "../store";
import ReviewOne from "../assets/review1.jpg";
import ReviewTwo from "../assets/8.jpg";
import ReviewThree from "../assets/6.jpg";

import { Cross } from "../assets/svgs";
let reviewArray = {
  0: {
    img: ReviewOne,
    review:
      `Australian soprano Sharon Kempton, an experienced and versatile soloist..had to cope with the
  most diverse roles in this programme and did so convincingly. Sharon Kempton has a pleasantly
  supple voice, which she produces with a perfect technique. She subtly brought out the melancholy
  nuances in the Countess of Figaro's Wedding Aria, then mastered the coloraturas in the Aria of
  Rosina from the Barber of Seville (actually not a soprano aria, but all the more admirable) in a
  breathtaking manner.`,
    author: "Frank Sydow",
    quote: "experienced and versatile",
  },
  1: {
    img: ReviewTwo,
    review:
      `With her large, very flexible voice she made the concert evening a real opera experience. From the
    most delicate piano to the large-frame forte, she performed the arias of Countess Almaviva from
    "Figaro's Marriage" and the Fiordiligi from "Cosi fan tutte" in a professional and touching manner. In
    the aria "Come scoglio" as well as the aria of Elena from "I vespri siciliana" she proved her class in
    extensive coloraturas.
    The Australian singer is no stranger to Wiesbaden. From 2003 to 2014 she was a member of the
    State Theatre ensemble, singing 38 different roles during this time. This professionalism and
    experience, along with a strong stage presence and a versatile voice, gave the evening a very
    special class.`,
    author: `By Beke Heeren-Pradt
  Wiesbaden Kurier`,
    quote: "strong stage presence and a versatile voice",
  },
  2: {
    img: ReviewThree,
    review:
      `Sharon Kempton proves as spinster Marianne Leitmetzerin that she is everything in the world,
  except a spinster. Shrill and witty, sometimes even thoughtful, she turns this role into a fine
  character study with fresh, brilliant soprano and lustful playing.`,
    author: "IOCO Kultur im Netz",
    quote: "Shrill and witty",
  },
};

function Card({ data, findKey, i, setId, clicky }) {
  return (
    <figure>
      <div>
        <img
          data-key={i}
          onClick={(e) => {
            findKey(e.target);
            clicky((t) => !t);
            let imageKey = e.target.getAttribute("data-key");
            console.log();
            setId(imageKey);
          }}
          src={data.img}
          alt="Sharon Kempton"
        />
      </div>
      <figcaption>
        <span>{data.quote}</span>
      </figcaption>
    </figure>
  );
}
export default Reviews;

function Reviews() {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [id, setId] = useState(0);
  const [anim, setAnim] = useState(false);
  const [data, setData] = useState(null);
  const [bigData, setBigData] = useState(null);
  const { state } = useContext(LanguageContext);
  const lastImage = useRef(null);
  const mainStyling = css`
    background: #f0f0f0;
    min-height: 100vh;
    width: 100%;
    display: grid;
    grid-template: 1 / 1;
    @media (max-width: 719px) {
      min-height: 100vh;
      height: auto;
    }
  `;
  const pageTitle = css`
    width: 100%;
    position: ${anim ? "absolute" : "relative"};
    visibility: ${anim ? "hidden" : "visible"};
    height: 40vh;
    grid-area: 1 / 1;
    display: flex;
    justify-content: center;
    z-index: 3;
    align-items: center;
    h1 {
      font-size: 3rem;
    }
  `;
  const topStyling = css`
    position: ${anim ? "absolute" : "relative"};
    visibility: ${anim ? "hidden" : "visible"};
    background: #f0f0f0;
    min-height: 100vh;
    /* min-width: 100%; */
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    align-items: center;
    grid-area: 1 / 1;
    @media (max-width: 1013px) {
      /* width: 250px; */
      /* margin-left: 3vmin; */
      justify-content: space-evenly;
    }
    @media (max-width: 969px) {
      padding-top: 40vh;
      /* width: 250px; */
      flex-direction: column;
      /* justify-content: space-evenly; */
    }
    > figure {
      flex: 0 0 auto;
      /* height: 50%; */
      /* width: 300px; */
      margin: 0;
      background: transparent;
      /* margin-left: 10vmin; */
      display: grid;
      grid-template-rows: 2fr 1fr;
      @media (max-width: 1210px) {
        width: 280px;
        margin-left: 0;
      }
      @media (max-width: 1013px) {
        /* width: 250px; */
        margin-left: 0;
        /* justify-content: space-evenly; */
      }
      @media (max-width: 969px) {
        /* width: 250px; */
        margin-left: 0;
        width: 70%;
        height: 30em;
      }
      @media (max-width: 645px) {
        /* width: 250px; */
        margin-left: 0;
        width: 90%;
        height: 38em;
      }
      > div {
        /* overflow: hidden; */
        animation: rotatewrap 0.4s cubic-bezier(0.5, 0, 0.5, 1) backwards 0.4s;
        opacity: 1;
        width: 350px;
        max-height: 245px;
        @media (max-width: 1200px) {
          width: 320px;
          max-height: 224.08px;
        }
        @media (max-width: 1090px) {
          width: 290px;
          max-height: 203.06px;
        }
        @media (max-width: 969px) {
          width: 100%;
          grid-column: 1;
          grid-row: 1;

          height: 100%;
          max-height: 100%;
          /* margin: 0 auto; */
          display: flex;
          justify-content: center;
          align-items: center;
        }
        @keyframes rotatewrap {
          from {
            transform: translateY(50vw) rotate(-20deg);
            opacity: 0;
          }
          to {
            transform: none;
          }
        }
      }

      img {
        max-width: 100%;
        height: auto;
        display: block;
        border-radius: 3px;
        transition: transform 0.3s ease-in-out;
        cursor: pointer;
        @media (max-width: 969px) {
          /* height: 100%; */
          max-height: 100%;
        }
        &:hover {
          transform: scale(1.2);
        }

        animation: inherit;
        animation-name: rotateimg;

        @keyframes rotateimg {
          from {
            transform: scale(1.4) rotate(20deg);
          }
          to {
            transform: none;
          }
        }
      }

      figcaption {
        /* margin-top: 1em; */
        text-transform: capitalize;
        align-self: center;
        @media (max-width: 1204px) {
          font-size: 0.9rem;
        }
        @media (max-width: 969px) {
          text-align: center;
          width: 40%;
          justify-self: center;
        }

        &::before,
        &::after {
          content: "";
          display: block;
          border: solid 1px;
          transform: ${anim ? `scaleX(0)` : `scaleX(1)`};
          transition: transform 0.6s cubic-bezier(0.5, 0, 0.5, 1);
          transform-origin: right center;
        }
        &::after {
          margin-top: 1em;
        }
        &::before {
          margin-bottom: 1em;
          transition-duration: 1s;
        }
        span {
          font-style: italic;
          quotes: auto;
          &::before {
            content: open-quote;
          }
          &:after {
            content: close-quote;
          }
        }
      }
    }
  `;

  const findKey = (el) => {
    let firstEl = el;
    let lastEl = lastImage.current;
    let firstRect = firstEl.getBoundingClientRect();
    let lastRect = lastEl.getBoundingClientRect();
    setData(firstRect);
    setBigData(lastRect);

    // INVERT
    requestAnimationFrame(() => {
      const deltaX = firstRect.left - lastRect.left;
      const deltaY = firstRect.top - lastRect.top;
      const deltaW = firstRect.width / lastRect.width;
      const deltaH = firstRect.height / lastRect.height;

      // PLAY
      lastEl.style.transition = "none";
      lastEl.style.transformOrigin = "top left";
      lastEl.style.transform =
        `translate(${deltaX}px, ${deltaY}px) scale(${deltaW}, ${deltaH})`;
      requestAnimationFrame(() => {
        console.log("animation frame!");
        lastEl.style.transition = "transform .5s ease-in";
        lastEl.style.transform = "none";
      });
    });
  };

  const zoomBack = () => {
    const lastEl = document.querySelector(`[data-key="${id}"]`);

    const firstRect = bigData;
    const lastRect = data;
    // console.log(JSON.stringify(bigData, 2, null));
    // console.log(JSON.stringify(firstEl.getBoundingClientRect(), 2, null));

    // INVERT
    requestAnimationFrame(() => {
      const deltaX = firstRect.left - lastRect.left;
      const deltaY = firstRect.top - lastRect.top;
      const deltaW = firstRect.width / lastRect.width;
      const deltaH = firstRect.height / lastRect.height;

      // PLAY
      lastEl.style.transition = "none";

      lastEl.style.transformOrigin = "top left";
      lastEl.style.transform =
        `translate(${deltaX}px, ${deltaY}px) scale(${deltaW}, ${deltaH})`;
      requestAnimationFrame(() => {
        console.log("animation frame!");
        lastEl.style.transition = "transform .5s ease-in";
        lastEl.style.transform = "none";
      });
    });
  };
  return (
    <main css={mainStyling}>
      <div css={pageTitle}>
        <h1>Reviews</h1>
      </div>
      <div css={topStyling}>
        {state.reviewsData.map((data, i) => (
          <Card
            data={data}
            key={i}
            findKey={findKey}
            i={i}
            clicky={setAnim}
            setId={setId}
          />
        ))}
      </div>
      <DetailReview
        setAnim={setAnim}
        anim={anim}
        lastImage={lastImage}
        id={id}
        zoomBack={zoomBack}
      />
    </main>
  );
}

function DetailReview({ id, anim, setAnim, lastImage, zoomBack }) {
  const reviewData = reviewArray[id];
  const imageLink = reviewData.img;

  const topStyling = css`
    position: ${anim ? "relative" : "absolute"};
    visibility: ${anim ? "visible" : "hidden"};

    opacity: ${anim ? 1 : 0};
    height: 100vh;
    grid-area: 1 / 1;
    width: 80%;
    display: flex;
    background: inherit;
    justify-content: flex-start;
    align-items: center;

    justify-self: center;
    /* transition: visibility 0.02s linear, opacity 0.3s linear; */
    z-index: 2;
    @media (max-width: 1407px) {
      width: 100%;
    }
    @media (max-width: 1119px) {
      align-items: center;
      justify-content: center;
    }
    @media (max-width: 900px) {
      min-height: 100vh;
      height: auto;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
    }

    figure {
      flex: 1 0 55%;
      margin: 0;
      img {
        display: block;
        max-width: 100%;
        border-radius: 3px;
        height: auto;
        transition: transform 0.3s;
      }
    }
    article {
      padding: 5vmin;
      align-self: stretch;

      /* margin: auto 0; */
      @media (max-width: 1171px) {
        padding: 0 1vmin 0 1vmin;
        display: grid;
        /* grid-auto-rows: auto; */
        grid-template-rows: 1fr 4fr 0.5fr;
      }
      @media (max-width: 1171px) {
        padding: 0 0 0 1vmin;
      }

      header {
        &::before,
        &::after {
          content: "";
          display: block;
          border: solid 1.2px;
          transform: ${anim ? "scaleX(1)" : "scaleX(0)"};
          transition: transform 0.6s cubic-bezier(0.5, 0, 0.5, 1);
          transform-origin: right center;
        }
        &::after {
          transition-duration: 1s;
        }
        h2 {
          font-size: 2.3rem;
          text-transform: capitalize;
          font-weight: bold;
          quotes: auto;
          font-style: italic;
          &:before {
            content: open-quote;
          }
          &:after {
            content: close-quote;
          }

          @media (max-width: 1171px) {
            font-size: 1.5rem;
            margin: 0.3rem;
          }

          /* @media (max-width: 804px) {
            font-size: 2rem;
          } */
          @media (max-width: 800px) {
            font-size: 1.5rem;
          }
        }
      }
    }
  `;
  const reviewText = css`
    line-height: 1.9;
    align-self: center;
    @media (max-width: 1171px) {
      line-height: 1.5;
    }
  `;
  const lastOfType = css`
    font-weight: 500;
    font-size: 1.2rem;
    padding: 0;
    margin: 0;
    align-self: flex-end;
    justify-self: flex-start;
  `;
  const innerContainer = css`
    width: 100vw;
    /* height: 100vh; */
    display: flex;
    background: inherit;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    /* > figure {
      flex: 1 0 55%;
      margin: 0;
      img {
        display: block;
        max-width: 100%;
        border-radius: 3px;
        height: auto;
        transition: transform 0.3s;
      }
    } */

    @media (max-width: 1119px) {
      /* height: 100vh; */
      display: grid;
      width: 60%;
      /* grid-template-rows: 40% 40%; */
      > article {
        header {
          align-self: center;
        }
      }
      > figure {
      }
    }

    @media (max-width: 719px) {
      /* min-height: auto; */

      display: grid;
      width: 70%;
      /* grid-template-rows: 2fr 1fr; */

      > article {
        align-self: flex-start;
      }
      > figure {
        align-self: center;
      }
    }

    @media (max-width: 565px) {
      width: 96%;
    }
  `;
  const goBackBtn = css`
    position: absolute;
    z-index: 3;
    top: 20px;
    right: 20px;
    background: transparent;
    outline: none;
    cursor: pointer;

    border: none;
    padding: 0.4em;
    border-radius: 2px;
  `;
  return (
    <section css={topStyling}>
      <button
        css={goBackBtn}
        onClick={() => {
          setAnim((t) => !t);
          zoomBack();
        }}
      >
        <Cross />
      </button>
      <div css={innerContainer}>
        <figure>
          <img ref={lastImage} src={imageLink} alt="Sharon Kempton" />
        </figure>
        <article>
          <header>
            <h2>{reviewData.quote}</h2>
          </header>
          <p css={reviewText}>{reviewData.review}</p>
          <p css={lastOfType}>{reviewData.author}</p>
        </article>
      </div>
    </section>
  );
}
