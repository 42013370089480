import { useReducer } from "react";
import { English, German, reducer } from "../store";
import languages from "../utils/languages";
import { setLocalStorage } from "../utils/types";
const storage = setLocalStorage.state;
const { german } = setLocalStorage.lang;
function useInit() {
  let defaultLan = languages();

  let langOption = localStorage.getItem(storage);
  let initialState = langOption === "German" ? German : English;
  if (defaultLan === "german" && langOption !== "English") {
    initialState = German;
    localStorage.setItem(storage, german);
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  return [state, dispatch];
}

export default useInit;
