/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";

export function DownArrow() {
  return (
    <svg
      width="40"
      height="79"
      viewBox="0 0 40 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 0V76M20 76L38 58M20 76L2 58"
        stroke="black"
        strokeWidth="3"
      />
    </svg>
  );
}

export function Cross({ color = "black" }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 2L22.5 22.5M22.5 2L2 22.5"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function WhiteCross() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 2L22.5 22.5M22.5 2L2 22.5"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function BgSvg() {
  return (
    <svg
      width="674"
      height="623"
      viewBox="0 0 674 623"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.7408 123.118C10.0731 191.347 -9.49728 276.116 4.33509 358.775C18.1675 441.434 64.2695 515.212 132.499 563.88C200.729 612.548 285.497 632.118 368.156 618.286C450.815 604.454 524.594 558.352 573.262 490.122L58.7408 123.118Z"
        fill="#C5E3F6"
      />
      <path
        d="M619.974 491.79C643.059 457.306 659.126 418.612 667.257 377.919C675.389 337.226 675.425 295.329 667.365 254.622C659.305 213.915 643.305 175.193 620.28 140.669C597.256 106.145 567.656 76.4939 533.172 53.4089C498.688 30.3239 459.995 14.2571 419.302 6.12577C378.609 -2.00555 336.712 -2.04211 296.005 6.01818C255.297 14.0785 216.576 30.0777 182.052 53.1025C147.528 76.1273 117.877 105.727 94.7917 140.211L619.974 491.79Z"
        fill="#C5E3F6"
      />
    </svg>
  );
}

export function PointRight() {
  const svgstyles = css`
    grid-area: svgslide;
    align-self: center;
    justify-self: center;
    width: 20vmin;
    align-self: flex-end;

    svg {
      width: 100;
      height: 100;
      animation: svgAnim 1.5s ease-in-out both;
      animation-delay: 0.2s;
      fill: rgb(20, 20, 20);
    }

    @keyframes svgAnim {
      from {
        transform: translateX(-1000%);
      }
    }

    @media (max-width: 1120px) {
      width: 15vmin;
      svg {
      }
    }

    @media (max-width: 843px) {
      display: none;
    }
  `;
  return (
    <div css={svgstyles}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z" />
      </svg>
    </div>
  );
}
