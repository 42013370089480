/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { contactPicture } from "../../assets/index";
function SmallVersion() {
  const { alt, img: src } = contactPicture;
  const topContainer = css`
    min-height: 100vh;
    height: auto;
  `;
  const spacing = css`
    height: 195px;
  `;
  const imageParent = css`
    width: 60vmin;
    height: 60vmin;
    margin: 0 auto;
    img {
      display: block;
      max-width: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: top center;
      border-radius: 3px;
    }
  `;

  const contactParent = css`
    max-width: 60vmin;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  `;

  return (
    <div css={topContainer}>
      <div css={spacing}></div>
      <div css={imageParent}>
        <img alt={alt} src={src} />
      </div>
      <div css={contactParent}>
        <h1>Contact</h1>
        <div>
          {" "}
          Sharon is based in Wiesbaden Germany. In addition to her singing
          career Sharon is also a professional Life and Performance Coach who
          can help guide and support singers navigating a performance career.
        </div>
        <div
          style={{
            paddingTop: "1em",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h3 style={{ margin: 0 }}>Email</h3>
          <p style={{ fontSize: "14px" }}>sekemptonsoprano@gmail.com</p>
        </div>
      </div>
    </div>
  );
}

export default SmallVersion;
