/** @jsxRuntime classic */
/** @jsx jsx */
import React, { lazy, Suspense } from "react";

import { jsx } from "@emotion/react";
// import { EntrancePhoto } from "../assets/index";
// import hoverEffect from "hover-effect";
// import entrance from "../assets/awards_1.jpg";
// import best from "../assets/awards_2.jpg";
// import noise from "../assets/noise.jpg";
import useMedia from "../hooks/useMedia";
import LargeVersion from "../comps/awards/LargeVersion";
const MobileVersion = lazy(() => import("../comps/awards/MobileVersion"));

function Awards() {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const mobile = useMedia("(max-width: 960px)");

  return (
    <>
      {mobile
        ? (
          <Suspense fallback={<div>Loading...</div>}>
            <MobileVersion />
          </Suspense>
        )
        : (
          <LargeVersion />
        )}
    </>
  );
}

export default Awards;
