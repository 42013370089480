export const homePageHerosEnglish = {
  bio: {
    text: `Australian born soprano Sharon Kempton, completed a Bachelor of
  Music Performance and Music Therapy with honours and Master of
  Music Performance at the University of Melbourne, under the
  tuition of Kevin Casey, Bettine MacCaughan OAM...`,
    button: "See Bio",
  },
  awards: {
    text: `First Prize Winner, German Australian Opera Grant
  First Prize Winner, German Operatic Award.`,
    button: "See Awards",
  },
  reviews: {
    text: `Sharon Kempton has a pleasantly
  supple voice, which she produces with a perfect technique. She subtly brought out the melancholy
  nuances in the Countess of Figaro.`,
    button: "See Reviews",
  },
  upcoming: { text: `See Sharon's upcoming shows.`, button: "See Shows" },
};
