import React from "react";
import { Link } from "react-router-dom";
export default function NotFound() {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>
        404: Page not found <Link to="/">Return Home</Link>
      </h1>
    </div>
  );
}
