/** @jsxRuntime classic */
/** @jsx jsx */
import { useContext } from "react";
import { Link } from "react-router-dom";
import { css, jsx } from "@emotion/react";
import { LanguageContext } from "../../store";

function Footer() {
  const { state } = useContext(LanguageContext);
  const { bio, reviews, career, awards, contact } = state.footer;
  const footerStyles = css`
    background: #232323;
    height: 27em;
    width: 100%;
    display: flex;
    align-items: center;

    ul {
      display: flex;
      width: 100%;
      justify-content: space-around;
      align-items: center;
      list-style: none;
      color: #ababab;
      font-size: 1.4rem;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;

      li {
        position: relative;
        transition: color 0.15s linear, transform 0.35s ease-in;
        overflow: hidden;
        cursor: pointer;
        padding: 0.7em;
        &:hover {
          transition: color 0.2s linear, transform 0.2s ease-in;
          color: white;
          transform: scale(1.1);
          &:after {
            animation: lineThro 0.5s linear;
            animation-iteration-count: 2;
          }
        }

        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background: white;
          left: 0;
          top: 50%;
          transform: translateX(-120%);
        }

        a {
          text-decoration: none;
          color: inherit;
        }
      }
    }
    @keyframes lineThro {
      from {
        transform: translateX(-100%);
      }
      to {
        transform: translateX(100%);
      }
    }
  `;

  return (
    <footer css={footerStyles}>
      <ul>
        <li>
          <Link to="/reviews">{reviews}</Link>
        </li>
        <li>
          <Link to="/contact">{career}</Link>
        </li>
        <li>
          <Link to="/awards">{awards}</Link>
        </li>
        <li>
          <Link to="/bio">{bio}</Link>
        </li>
        <li>
          <Link to="/contact">{contact}</Link>
        </li>
      </ul>
    </footer>
  );
}
export default Footer;
