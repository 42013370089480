/** @jsxRuntime classic */
/** @jsx jsx */
import { useReducer, useRef } from "react";
import { css, jsx } from "@emotion/react";
import { Cross } from "../../assets/svgs";
import { slides } from "../../assets/index";
const carouselReducer = (state, action) => {
  switch (action.type) {
    case "NEXT":
      return { ...state, index: (state.index + 1) % 9 };
    case "PREV":
      return { ...state, index: (state.index - 1 + 9) % 9 };
    default:
      return state;
  }
};
function Carousel({ id, remove, getBodyClass }) {
  // const id = { props } || 0;
  const ref = useRef();
  const goBack = (e) => {
    const { current } = ref;
    if (current === e.target) {
      remove();
      getBodyClass();
    }
  };
  const buttonClick = () => {
    remove();
    getBodyClass();
  };

  const initialState = { index: id };
  const [state, dispatch] = useReducer(carouselReducer, initialState);
  const carouselStyle = css`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background: rgba(0, 0, 0, 0.3); */
    background: #fafafa;

    z-index: 100;
  `;

  const imageStyle = css`
    display: block;
    height: 100%;
    max-width: 80%;
    width: auto;
    opacity: 1;
    max-height: 90%;
    margin: 0 auto;
    animation: fadeIn 0.25s linear;
    object-fit: contain;
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
    }
  `;

  const imageWrapper = css`
    position: relative;

    height: 100vh;
    display: grid;
    place-items: center center;
    overflow-y: hidden;
  `;

  const btnLeft = css`
    position: absolute;
    top: 50%;
    left: 5%;
    display: flex;
    background: none;
    outline: none;
    border: none;
    width: 48px;
    height: 48px;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
      background: lightgray;
    }
    @media (max-width: 780px) {
      background: lightgrey;
    }
  `;
  const btnRight = css`
    position: absolute;
    top: 50%;
    right: 5%;
    display: flex;
    background: none;
    outline: none;
    border: none;
    width: 48px;
    height: 48px;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
      background: lightgray;
    }
    @media (max-width: 780px) {
      background: lightgrey;
    }
  `;
  const exitButton = css`
    position: fixed;
    top: 30px;
    left: 30px;
    background: transparent;
    outline: none;
    z-index: 100;
    border: none;
    padding: 0.4em;
    border-radius: 2px;
    transition: background 0.2s ease-in;
    cursor: pointer;

    &:hover {
      background: lightgray;
    }
    svg {
      animation: scaleIt 0.25s ease-in-out;

      @keyframes scaleIt {
        from {
          transform: scale(0.4);
        }
      }
    }
  `;
  return (
    <div onClick={(e) => goBack(e)} css={carouselStyle}>
      <button css={exitButton}>
        <Cross />
      </button>
      <div ref={ref} css={imageWrapper}>
        <button onClick={() => buttonClick()} css={exitButton}>
          <Cross />
        </button>
        <button css={btnLeft} onClick={() => dispatch({ type: "PREV" })}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 0 24 24"
            width="48"
          >
            <path
              d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
            />
            <path d="M0 0h24v24H0V0z" fill="none" />
          </svg>
        </button>{" "}
        <img
          css={imageStyle}
          src={slides[state.index]}
          alt="Sharon Kempton on Stage"
        />{" "}
        <button css={btnRight} onClick={() => dispatch({ type: "NEXT" })}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 0 24 24"
            width="48"
          >
            <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
            <path d="M0 0h24v24H0V0z" fill="none" />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default Carousel;
