import React from "react";
import { useParams } from "react-router-dom";

import Biography from "../pages/Biography";
import Reviews from "../pages/Reviews";
import Awards from "../pages/Awards";
import ContactPage from "../pages/ContactPage";
import Calendar from "../pages/Calendar";
import Gallery from "../pages/Gallery";
import NotFound from "../pages/NotFound";
export default function Child() {
  let { id } = useParams();

  switch (id) {
    case "reviews":
      return <Reviews />;
    case "calendar":
      return <Calendar />;
    case "contact":
      return <ContactPage />;
    case "awards":
      return <Awards />;
    case "bio":
      return <Biography />;
    case "gallery":
      return <Gallery />;
    default:
      return <NotFound />;
  }
}
