/** @jsxRuntime classic */
/** @jsx jsx */

import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { css, jsx } from "@emotion/react";
import { LanguageContext } from "../../store";
import { WhiteCross } from "../../assets/svgs";
import useMedia from "../../hooks/useMedia";

function Nav() {
  const { state, dispatch } = useContext(LanguageContext);
  const [atest, setTest] = useState(false);
  const location = useLocation();
  useEffect(() => {
    let title = location.pathname;
    if (title === "/") {
      document.title = "Sharon Kempton";
    } else {
      const cleanedTitle = title.replace("/", "")[0].toUpperCase() +
        title.slice(2);
      document.title = cleanedTitle;
    }
  }, [location.pathname]);

  useEffect(() => {
    localStorage.setItem("languageOption", state.userLang);
  }, [state.isEnglish]);
  const bigMenu = useRef(null);
  const littleMenu = useRef(null);
  let small = useMedia("(max-width: 700px)");
  let large = useMedia("(min-width: 700px)");
  const reveal = () => {
    let App = document.querySelector("#root");
    requestAnimationFrame(() => {
      const firstRect = littleMenu.current.getBoundingClientRect();
      const lastRect = bigMenu.current.getBoundingClientRect();

      const deltaX = firstRect.left - lastRect.left;
      const deltaY = firstRect.top - lastRect.top;
      const deltaW = firstRect.width / lastRect.width;
      const deltaH = firstRect.height / lastRect.height;

      bigMenu.current.animate(
        [
          {
            transform:
              `translateX(${deltaX}px) translateY(${deltaY}px) scale(${deltaW}, ${deltaH})`,
          },
          { transform: "none" },
        ],
        { duration: 600, easing: "cubic-bezier(.2, 0, .3, 1)" },
      );
      App.dataset.view = "bigmenu";
    });
  };
  const remove = () => {
    let App = document.querySelector("#root");
    requestAnimationFrame(() => {
      const firstRect = bigMenu.current.getBoundingClientRect();
      const lastRect = littleMenu.current.getBoundingClientRect();

      const deltaX = firstRect.left - lastRect.left;
      const deltaY = firstRect.top - lastRect.top;
      const deltaW = firstRect.width / lastRect.width;
      const deltaH = firstRect.height / lastRect.height;

      littleMenu.current.animate(
        [
          {
            transform:
              `translateX(${deltaX}px) translateY(${deltaY}px) scale(${deltaW}, ${deltaH})`,
          },
          { transform: "none" },
        ],
        { duration: 600, easing: "cubic-bezier(.2, 0, .3, 1)" },
      );
      App.dataset.view = "nomenu";
    });
  };
  const navStyles = css`
    /* margin: 0 0 0 1.5em; */
    padding: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    height: 193px;
    flex-direction: column;
    width: 8em;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: black;
    z-index: 5;

    @media (max-width: 470px) {
      height: 160px;
    }
    @media (max-width: 420px) {
      height: 140px;
    }
    @media (max-width: 380px) {
      height: 120px;
      width: 5em;
    }
  `;

  function handleClick() {
    state.isEnglish
      ? dispatch({ type: "German" })
      : dispatch({ type: "English" });

    return;
  }

  const menuContainer = css`
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    background: #232323;
    width: 100%;
    /* height: 100%; */
    transform-origin: top left;
    font-family: "Prata", serif;
    color: white;
    cursor: pointer;

    button {
      grid-row: 4;
      align-self: center;
      justify-self: center;
      margin: 0 0 1em 0;
      padding: 0;
      background: none;
      color: white;
      font-size: 1.3rem;
      font-weight: 400;
      text-transform: uppercase;
      outline: none;
      border: none;
      cursor: pointer;
      font-family: inherit;

      @media (max-width: 415px) {
        margin: 0;
        padding-bottom: 0.4em;
      }
      @media (max-width: 380px) {
        font-size: 0.8rem;
      }
    }
  `;
  const SharonS = css`
    grid-row: 2;
    color: inherit;
    align-self: center;
    justify-self: center;
    font-size: 3rem;
    padding: 0.1em 0.2em 0 0.2em;
    display: grid;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    @media (max-width: 380px) {
      font-size: 1.8rem;
    }
  `;
  const showNav = () => setTest((b) => !b);
  const props = { handleClick, state: state, atest, showNav };

  return (
    <>
      <FullNav onClick={remove} bigMenu={bigMenu} />
      <div css={navStyles}>
        {small && <Mobile {...props} />}

        <div
          onClick={reveal}
          className="LittleMenu"
          ref={littleMenu}
          css={menuContainer}
        >
          {" "}
          <div css={SharonS}>S</div>
          <button>Menu</button>
        </div>

        {/* <div css={orange}></div> */}
        {
          /* <ul css={ulStyling}>
          <li>
            <Link to="/">{state.home}</Link>
          </li>
          <li>
            <Link to="/gallery">{state.gallery}</Link>
          </li>
          <li>
            <Link to="/bio">{state.bio}</Link>
          </li>
          <li>
            <Link to="/reviews">{state.reviews}</Link>
          </li>
          <li>
            <Link to="/awards">{state.awards}</Link>
          </li>
          <li>
            <Link to="/contact">{state.contact}</Link>
          </li>
          <li>
            <Link onClick={showNav} to="/calendar">
              {state.calendar}
            </Link>
          </li>
          <button css={btnStyles} onClick={handleClick}>
            <Flag isEnglish={state.isEnglish} />
          </button>
        </ul> */
        }
      </div>
    </>
  );
}

export function FullNav({ bigMenu, onClick }) {
  const { state, dispatch } = useContext(LanguageContext);
  const [atest, setTest] = useState(false);
  const showNav = () => setTest((b) => !b);
  function handleClick() {
    state.isEnglish
      ? dispatch({ type: "German" })
      : dispatch({ type: "English" });

    return;
  }
  const mainStyle = css`
    --lighter-color: #ff9f68;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #232323;
    z-index: 120;
    margin: 0 auto;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    transform-origin: top left;

    a {
      color: white;
      /* background-image: linear-gradient(
        180deg,
        var(--lighter-color) 0,
        var(--lighter-color)
      ); */
      font-size: 3rem;
      /* background-position: 0 2.5em;
      background-repeat: no-repeat;
      background-size: 100%;
      transition: background-position 0.15s 0.1s; */
      display: inline;
      cursor: pointer;
      &:hover {
        /* background-position: 0em 0em; */
      }
    }
  `;
  const ulStyling = css`
    margin: 0;
    padding: 0;
    background: none;
    height: 80%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;

    align-items: center;
    color: black;
    list-style: none;
    a {
      text-decoration: none;
    }
  `;
  const btnStyles = css`
    outline: none;
    border: none;
    cursor: pointer;
    display: block;
    background: none;
    padding: 0;
    margin: 0;
  `;
  const whiteCross = css`
    position: absolute;
    top: 30px;
    left: 30px;
    svg {
      cursor: pointer;
      transition: transform 0.2s ease-in-out;
    }
    &:hover {
      svg {
        transform: scale(1.4);
      }
    }
  `;
  return (
    <div onClick={onClick} className="FullNav" ref={bigMenu} css={mainStyle}>
      <div css={whiteCross}>
        <WhiteCross />
      </div>
      <ul css={ulStyling}>
        <li>
          <NavLink exact activeStyle={{ color: "var(--lighter-color)" }} to="/">
            {state.home}
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            activeStyle={{ color: "var(--lighter-color)" }}
            to="/gallery"
          >
            {state.gallery}
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            activeStyle={{ color: "var(--lighter-color)" }}
            to="/bio"
          >
            {state.bio}
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            activeStyle={{ color: "var(--lighter-color)" }}
            to="/reviews"
          >
            {state.reviews}
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            activeStyle={{ color: "var(--lighter-color)" }}
            to="/awards"
          >
            {state.awards}
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            activeStyle={{ color: "var(--lighter-color)" }}
            to="/contact"
          >
            {state.contact}
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            activeStyle={{ color: "var(--lighter-color)" }}
            onClick={showNav}
            to="/calendar"
          >
            {state.calendar}
          </NavLink>
        </li>
        <button css={btnStyles} onClick={handleClick}>
          <Flag isEnglish={state.isEnglish} />
        </button>
      </ul>
    </div>
  );
}

function Mobile({ state, handleClick, atest, showNav }) {
  const ulStyling = css`
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    width: 50%;
    align-items: center;
    color: white;
    list-style: none;
    @media (max-width: 920px) {
      width: 70%;
    }
    @media (max-width: 700px) {
      flex-direction: column;

      height: 50%;
    }
    a {
      text-decoration: none;
      font-size: 1.3rem;
      color: whitesmoke;
    }
  `;

  const btnStyles = css`
    outline: none;
    border: none;
    cursor: pointer;
    display: block;
    background: none;
    padding: 0;
    margin: 0;
  `;

  const mobileStyle = css`
    position: absolute;
    display: ${atest ? "block" : "none"};
    visibility: ${atest ? "visible" : "hidden"};
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to right, rgb(255, 153, 102), rgb(255, 94, 98));
    z-index: 6;
    overflow: hidden;
    animation: fadeInFromNone 0.2s ease-out;

    @keyframes fadeInFromNone {
      0% {
        display: none;
        opacity: 0;
      }

      1% {
        display: block;
        opacity: 0;
      }

      100% {
        display: block;
        opacity: 1;
      }
    }
  `;
  return (
    <div css={mobileStyle}>
      <ul css={ulStyling}>
        <li>
          <Link onClick={showNav} to="/">
            {" "}
            {state.home}
          </Link>
        </li>
        <li>
          <Link onClick={showNav} to="/gallery">
            {state.gallery}
          </Link>
        </li>
        <li>
          <Link onClick={showNav} to="/bio">
            {state.bio}
          </Link>
        </li>
        <li>
          <Link onClick={showNav} to="/reviews">
            {state.reviews}
          </Link>
        </li>
        <li>
          <Link onClick={showNav} to="/contact">
            {state.contact}
          </Link>
        </li>
        <li>
          <Link onClick={showNav} to="/calendar">
            {state.calendar}
          </Link>
        </li>
        <button css={btnStyles} onClick={handleClick}>
          <Flag isEnglish={state.isEnglish} />
        </button>
      </ul>
    </div>
  );
}

function Flag({ isEnglish }) {
  return isEnglish
    ? (
      <svg
        width="48"
        height="32"
        viewBox="0 0 48 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.104492" width="48" height="31.3043" fill="#01237B" />
        <rect
          x="-4.69531"
          y="30.8022"
          width="65.0188"
          height="5.20813"
          transform="rotate(-32 -4.69531 30.8022)"
          fill="white"
        />
        <rect
          x="-3.96484"
          y="32.5762"
          width="65.0188"
          height="2.39061"
          transform="rotate(-32 -3.96484 32.5762)"
          fill="#CB122B"
        />
        <rect
          x="-1.93359"
          y="-3.65234"
          width="65.0188"
          height="5.20813"
          transform="rotate(32 -1.93359 -3.65234)"
          fill="white"
        />
        <rect
          x="-2.69922"
          y="-1.87891"
          width="65.0188"
          height="2.39061"
          transform="rotate(32 -2.69922 -1.87891)"
          fill="#CB122B"
        />
        <rect y="10.7476" width="48" height="10.0174" fill="white" />
        <rect
          x="18.9844"
          y="39.7549"
          width="48"
          height="10.0174"
          transform="rotate(-90 18.9844 39.7549)"
          fill="white"
        />
        <rect
          x="20.8711"
          y="0.104492"
          width="6.26087"
          height="31.4087"
          fill="#CB122B"
        />
        <rect
          x="48"
          y="12.626"
          width="6.26087"
          height="48"
          transform="rotate(90 48 12.626)"
          fill="#CB122B"
        />
      </svg>
    )
    : (
      <svg
        width="48"
        height="33"
        viewBox="0 0 48 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.899902" width="48" height="31.3043" fill="#C4C4C4" />
        <rect y="0.79541" width="48" height="10.4348" fill="black" />
        <rect y="11.2305" width="48" height="10.4348" fill="#DD0000" />
        <rect y="21.665" width="48" height="10.4348" fill="#FFCE00" />
      </svg>
    );
}
export default Nav;
