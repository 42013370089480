let language = { english: "english", german: "german" };
export default function whichLanguage() {
  let userLanguage = navigator.language.toLocaleLowerCase();

  const { german, english } = language;
  switch (userLanguage) {
    case "de":
      return german;
    case "de-de":
      return german;
    case "de-at":
      return german;
    case "de-li":
      return german;
    case "de-lu":
      return german;
    case "du-ch":
      return german;
    default:
      return english;
  }
}
