/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Link } from "react-router-dom";
import PerfPhoto from "../../assets/4.jpg";
import {
  awardsPicData,
  ReviewPicData,
  upcomingPerformances,
} from "../../assets/index";

export function BioHero({ data }) {
  const { text, button } = data;
  const mainSyles = css`
    box-sizing: border-box;
    height: auto;
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
    background: white;
    display: grid;
    grid-template-rows: 100px auto;
  `;
  const gridChild = css`
    grid-row: 2;
  `;
  const widthWrapper = css`
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 10% 1fr 1fr 2fr 10%;
    grid-template-rows: 0.8fr 1fr 1fr 10%;

    @media (max-width: 710px) {
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 650px) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1.5fr 1fr 1fr;
    }

    @media (max-width: 600px) {
      grid-template-columns: 5% 1fr 1fr 5%;
      grid-template-rows: 1fr 10% 1fr;
    }
  `;
  const readMoreStyles = css`
    grid-row: 2 / -1;
    grid-column: 2 /4;
    background: white;
    padding: 2em;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
    width: 500px;
    height: 400px;
    display: grid;
    z-index: 2;

    @media (max-width: 856px) {
      grid-column: 1 / 3;
      align-self: flex-end;
    }
    @media (max-width: 710px) {
      width: 450px;
      height: 350px;
      padding: 1em;
      grid-column: 1 / -1;
      h3 {
        font-size: 2rem;
      }
      > div {
        font-size: 1.1rem;
        line-height: 1.4;
      }
    }

    @media (max-width: 605px) {
      width: 400px;
      height: 340px;
    }
    @media (max-width: 600px) {
      grid-row: 2 / -1;
      grid-column: 2 / -1;
      width: auto;
      height: auto;
    }

    h3 {
      margin: 0;
      font-size: 2.5rem;
      font-weight: 300;
      @media (max-width: 710px) {
        font-size: 2rem;
      }
      @media (max-width: 587px) {
        font-size: 2.1rem;
      }
    }

    a {
      color: black;
      justify-self: flex-start;
      align-self: center;
      text-decoration: none;
      font-size: 1.1rem;
      text-transform: uppercase;

      padding: 1em 1.2em;
      border: 1px solid grey;
      transition: background 0.1s linear, color 0.01s linear,
        border-color 0.01s linear;
      box-sizing: border-box;
      &:hover {
        border-color: white;
        background: linear-gradient(
          to right,
          rgb(255, 153, 102),
          rgb(255, 94, 98)
        );
        color: white;
      }
      @media (max-width: 710px) {
        font-size: 1rem;
        padding: 0.9em 1em;
      }
    }
  `;

  const textStyles = css`
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.4;
    @media (max-width: 587px) {
      line-height: 1.6;
      margin-top: 0.8em;
      margin-bottom: 0.8em;
    }
  `;
  const imageSyles = css`
    grid-row: 1 / -1;
    grid-column: 4 / -1;
    background: url(${PerfPhoto});
    width: 400px;
    height: 600px;
    background-size: cover;
    background-position: center center;
    z-index: 1;
    @media (max-width: 856px) {
      width: auto;
      height: auto;
      /* align-self: flex-end; */
    }
    @media (max-width: 769px) {
      grid-row: 1 / 3;
    }
    @media (max-width: 710px) {
      grid-column: 2;
      grid-row: 1 / 3;
    }
    @media (max-width: 650px) {
      grid-column: 2 / -1;
    }
    @media (max-width: 600px) {
      grid-column: 1 / 4;
      grid-row: 1 / 3;
    }
  `;
  return (
    <div css={mainSyles}>
      <div css={gridChild}>
        <article css={widthWrapper}>
          <div css={readMoreStyles}>
            <h3>Sharon</h3>
            <div css={textStyles}>{text}</div>

            <Link to="/bio">{button}</Link>
          </div>
          <div css={imageSyles}></div>
        </article>
      </div>
    </div>
  );
}

export function AwardsHero({ data }) {
  const { text, button } = data;
  const { img } = awardsPicData;
  const mainSyles = css`
    box-sizing: border-box;
    height: auto;
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
    background: white;
    display: grid;
    grid-template-rows: 100px auto;
  `;
  const gridChild = css`
    grid-row: 2;
  `;
  const widthWrapper = css`
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 10% 1fr 1fr 2fr 10%;
    grid-template-rows: 0.8fr 1fr 1fr 10%;

    @media (max-width: 710px) {
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 650px) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1.5fr 1fr 1fr;
    }

    @media (max-width: 600px) {
      grid-template-columns: 5% 1fr 1fr 5%;
      grid-template-rows: 1fr 10% 1fr;
    }
  `;
  const readMoreStyles = css`
    grid-row: 2 / -1;
    grid-column: 2 /4;
    background: white;
    padding: 2em;

    /* @media (max-width: 710px){
        grid-template-rows: 1.2fr 1fr 1fr 10%;
      } */
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
    width: 500px;
    height: 400px;
    display: grid;
    z-index: 2;
    @media (max-width: 856px) {
      grid-column: 1 / 3;
      align-self: flex-end;
    }
    @media (max-width: 710px) {
      width: 450px;
      height: 350px;
      padding: 1em;
      grid-column: 1 / -1;
      h3 {
        font-size: 2rem;
      }
      > div {
        font-size: 1.1rem;
        line-height: 1.4;
      }
    }

    @media (max-width: 605px) {
      width: 400px;
      height: 340px;
    }
    @media (max-width: 600px) {
      grid-row: 2 / -1;
      grid-column: 2 / -1;
      width: auto;
      height: auto;
    }

    h3 {
      margin: 0;
      font-size: 2.5rem;
      font-weight: 300;
      @media (max-width: 710px) {
        font-size: 2rem;
      }
      @media (max-width: 587px) {
        font-size: 2.1rem;
      }
    }

    a {
      color: black;
      justify-self: flex-start;
      align-self: center;
      text-decoration: none;
      font-size: 1.1rem;
      text-transform: uppercase;

      padding: 1em 1.2em;
      border: 1px solid grey;
      transition: background 0.1s linear, color 0.01s linear,
        border-color 0.01s linear;
      box-sizing: border-box;
      &:hover {
        border-color: white;
        background: linear-gradient(
          to right,
          rgb(255, 153, 102),
          rgb(255, 94, 98)
        );
        color: white;
      }
      @media (max-width: 710px) {
        font-size: 1rem;
        padding: 0.9em 1em;
      }
    }
  `;

  const textStyles = css`
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.4;
    @media (max-width: 587px) {
      line-height: 1.6;
      margin-top: 0.8em;
      margin-bottom: 0.8em;
    }
  `;
  const imageSyles = css`
    grid-row: 1 / -1;
    grid-column: 4 / -1;
    background: url(${img});
    width: 400px;
    height: 600px;
    background-size: cover;
    background-position: center center;
    z-index: 1;
    @media (max-width: 856px) {
      width: auto;
      height: auto;
      /* align-self: flex-end; */
    }
    @media (max-width: 769px) {
      grid-row: 1 / 3;
    }
    @media (max-width: 710px) {
      grid-column: 2;
      grid-row: 1 / 3;
    }
    @media (max-width: 650px) {
      grid-column: 2 / -1;
    }
    @media (max-width: 600px) {
      grid-column: 1 / 4;
      grid-row: 1 / 3;
    }
  `;
  return (
    <div css={mainSyles}>
      <div css={gridChild}>
        <article css={widthWrapper}>
          <div css={readMoreStyles}>
            <h3>Awards</h3>
            <div css={textStyles}>{text}</div>

            <Link to="/awards">{button}</Link>
          </div>
          <div css={imageSyles}></div>
        </article>
      </div>
    </div>
  );
}
export function ReviewsHero({ data }) {
  const { text, button } = data;
  const { img } = ReviewPicData;
  const mainSyles = css`
    box-sizing: border-box;
    height: auto;
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
    background: white;
    display: grid;
    grid-template-rows: 100px auto;
  `;
  const gridChild = css`
    grid-row: 2;
  `;
  const widthWrapper = css`
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 10% 1fr 1fr 2fr 10%;
    grid-template-rows: 0.8fr 1fr 1fr 10%;

    @media (max-width: 710px) {
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 650px) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1.5fr 1fr 1fr;
    }

    @media (max-width: 600px) {
      grid-template-columns: 5% 1fr 1fr 5%;
      grid-template-rows: 1fr 10% 1fr;
    }
  `;
  const readMoreStyles = css`
    grid-row: 2 / -1;
    grid-column: 2 /4;
    background: white;
    padding: 2em;

    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
    width: 500px;
    height: 400px;
    display: grid;
    z-index: 2;
    @media (max-width: 856px) {
      grid-column: 1 / 3;
      align-self: flex-end;
    }
    @media (max-width: 710px) {
      width: 450px;
      height: 350px;
      padding: 1em;
      grid-column: 1 / -1;
      h3 {
        font-size: 2rem;
      }
      > div {
        font-size: 1.1rem;
        line-height: 1.4;
      }
    }

    @media (max-width: 605px) {
      width: 400px;
      height: 340px;
    }
    @media (max-width: 600px) {
      grid-row: 2 / -1;
      grid-column: 2 / -1;
      width: auto;
      height: auto;
    }

    h3 {
      margin: 0;
      font-size: 2.5rem;
      font-weight: 300;
      @media (max-width: 710px) {
        font-size: 2rem;
      }
      @media (max-width: 587px) {
        font-size: 2.1rem;
      }
    }

    a {
      color: black;
      justify-self: flex-start;
      align-self: center;
      text-decoration: none;
      font-size: 1.1rem;
      text-transform: uppercase;

      padding: 1em 1.2em;
      border: 1px solid grey;
      transition: background 0.1s linear, color 0.01s linear,
        border-color 0.01s linear;
      box-sizing: border-box;
      &:hover {
        border-color: white;
        background: linear-gradient(
          to right,
          rgb(255, 153, 102),
          rgb(255, 94, 98)
        );
        color: white;
      }
      @media (max-width: 710px) {
        font-size: 1rem;
        padding: 0.9em 1em;
      }
    }
  `;

  const textStyles = css`
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.4;
    @media (max-width: 587px) {
      line-height: 1.6;
      margin-top: 0.8em;
      margin-bottom: 0.8em;
    }
  `;
  const imageSyles = css`
    grid-row: 1 / -1;
    grid-column: 4 / -1;
    background: url(${img});
    width: 400px;
    height: 600px;
    background-size: cover;
    background-position: center center;
    z-index: 1;
    @media (max-width: 856px) {
      width: auto;
      height: auto;
      /* align-self: flex-end; */
    }
    @media (max-width: 769px) {
      grid-row: 1 / 3;
    }
    @media (max-width: 710px) {
      grid-column: 2;
      grid-row: 1 / 3;
    }
    @media (max-width: 650px) {
      grid-column: 2 / -1;
    }
    @media (max-width: 600px) {
      grid-column: 1 / 4;
      grid-row: 1 / 3;
    }
  `;
  return (
    <div css={mainSyles}>
      <div css={gridChild}>
        <article css={widthWrapper}>
          <div css={readMoreStyles}>
            <h3>Reviews</h3>
            <div css={textStyles}>{text}</div>

            <Link to="/reviews">{button}</Link>
          </div>
          <div css={imageSyles}></div>
        </article>
      </div>
    </div>
  );
}

export function PerformanceHero({ data }) {
  const { text, button } = data;
  const { img } = upcomingPerformances;
  const mainSyles = css`
    box-sizing: border-box;
    height: auto;
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
    background: white;
    display: grid;
    grid-template-rows: 100px auto;
  `;
  const gridChild = css`
    grid-row: 2;
    padding-bottom: 2em;
  `;
  const widthWrapper = css`
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 10% 1fr 1fr 2fr 10%;
    grid-template-rows: 0.8fr 1fr 1fr 10%;

    @media (max-width: 710px) {
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 650px) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1.5fr 1fr 1fr;
    }

    @media (max-width: 600px) {
      grid-template-columns: 5% 1fr 1fr 5%;
      grid-template-rows: 1fr 10% 1fr;
    }
  `;
  const readMoreStyles = css`
    grid-row: 2 / -1;
    grid-column: 2 /4;
    background: white;
    padding: 2em;

    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
    width: 500px;
    height: 400px;
    display: grid;
    z-index: 2;
    @media (max-width: 856px) {
      grid-column: 1 / 3;
      align-self: flex-end;
    }
    @media (max-width: 710px) {
      width: 450px;
      height: 350px;
      padding: 1em;
      grid-column: 1 / -1;
      h3 {
        font-size: 2rem;
      }
      > div {
        font-size: 1.05rem;
        line-height: 1.6;
      }
    }

    @media (max-width: 605px) {
      width: 400px;
      height: 340px;
    }
    @media (max-width: 600px) {
      grid-row: 2 / -1;
      grid-column: 2 / -1;
      width: auto;
      height: auto;
    }

    h3 {
      margin: 0;
      font-size: 2.5rem;
      font-weight: 300;
      @media (max-width: 710px) {
        font-size: 2rem;
      }
      @media (max-width: 587px) {
        font-size: 2.1rem;
      }
    }

    a {
      color: black;
      justify-self: flex-start;
      align-self: center;
      text-decoration: none;
      font-size: 1.1rem;
      text-transform: uppercase;

      padding: 1em 1.2em;
      border: 1px solid grey;
      transition: background 0.1s linear, color 0.01s linear,
        border-color 0.01s linear;
      box-sizing: border-box;
      &:hover {
        border-color: white;
        background: linear-gradient(
          to right,
          rgb(255, 153, 102),
          rgb(255, 94, 98)
        );
        color: white;
      }
      @media (max-width: 710px) {
        font-size: 1rem;
        padding: 0.9em 1em;
      }
    }
  `;

  const textStyles = css`
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.4;
    @media (max-width: 587px) {
      line-height: 1.6;
      margin-top: 0.8em;
      margin-bottom: 0.8em;
    }
  `;
  const imageSyles = css`
    grid-row: 1 / -1;
    grid-column: 4 / -1;
    background: url(${img});
    width: 400px;
    height: 600px;
    background-size: cover;
    background-position: center center;
    z-index: 1;
    @media (max-width: 856px) {
      width: auto;
      height: auto;
      /* align-self: flex-end; */
    }
    @media (max-width: 769px) {
      grid-row: 1 / 3;
    }
    @media (max-width: 710px) {
      grid-column: 2;
      grid-row: 1 / 3;
    }
    @media (max-width: 650px) {
      grid-column: 2 / -1;
    }
    @media (max-width: 600px) {
      grid-column: 1 / 4;
      grid-row: 1 / 3;
      background-position: center top;
    }
  `;
  return (
    <div css={mainSyles}>
      <div css={gridChild}>
        <article css={widthWrapper}>
          <div css={readMoreStyles}>
            <h3>Upcoming Performances</h3>
            <div css={textStyles}>{text}</div>
            <Link to="/calendar">{button}</Link>
          </div>
          <div css={imageSyles}></div>
        </article>
      </div>
    </div>
  );
}
