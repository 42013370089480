/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useContext } from "react";
import { css, jsx } from "@emotion/react";

import { LanguageContext } from "../store";
import { whiteDress } from "../assets/index";

import {
  AwardsHero,
  BioHero,
  PerformanceHero,
  ReviewsHero,
} from "../comps/home/Comps";
import { Link } from "react-router-dom";
import useMedia from "../hooks/useMedia";
function Home() {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { state } = useContext(LanguageContext);
  const { homeHeros } = state;
  const { bio, reviews, awards, upcoming } = homeHeros;
  const mobile = useMedia("(max-width: 800px)");
  const homeStyle = css`
    height: auto;
  `;

  const headerStyles = css`
    position: relative;
    width: 100%;
    min-height: 100vh;
    height: 100vh;
    display: flex;
    background: rgba(255, 102, 68, 1);
    /* background: #ffd89d; */
    /* background: #ffc46c; */
    /* #ffd89d */
    h1,
    h2 {
      margin: 0;
    }

    a {
      cursor: pointer;
    }
    @media (max-width: 800px) {
      display: block;
      height: 100%;
    }
  `;
  const titleContainer = css`
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  `;
  const imageWrapper = css`
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1243px) {
      width: 70%;
    }
  `;
  const heroImageStyle = css`
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
    object-position: top;
    @media (max-width: 1100px) {
      object-position: right top;
    }
    @media (max-width: 950px) {
      object-position: 65%;
    }
  `;
  const imageMobileStyles = css`
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
    object-position: top;
  `;

  const testingit = css`
    position: absolute;
    top: 50%;
    right: -90px;
    transform: rotate(90deg);
    font-weight: 500;
    font-size: 30px;
  `;
  const lineStyle = css`
    height: 1.5px;
    width: 100%;
    background: black;
  `;
  const imageCenterStyle = { width: "80%", height: "90%" };
  const fontsStyle = css`
    font-family: "Prata", serif;
  `;
  const blurbStyles = css`
    font-size: 18px;
    margin: 0;

    text-align: left;
    line-height: 1.7;
    @media (max-width: 840px) {
      padding-top: 3em;
    }
  `;
  const mobileTextStyles = css`
    position: relative;
    font-size: 16px;
    letter-spacing: 0.02em;
    padding: 0em 2em 0.5em 2em;
    line-height: 1.6em;
    font-family: "Lato", sans-serif;
    margin: 0;
  `;
  const mobileTextStyles2 = css`
    font-size: 16px;
    letter-spacing: 0.02em;
    padding: 0.5em 2em 2em 2em;
    line-height: 1.6em;
    font-family: "Lato", sans-serif;
  `;
  const thickLineStyle = css`
    height: 4em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      background: black;
      height: 5px;
      width: 80%;
    }
  `;
  return (
    <div css={homeStyle}>
      <article css={headerStyles}>
        {mobile
          ? (
            <>
              <div>
                {" "}
                <img
                  css={imageMobileStyles}
                  src={whiteDress.img}
                  alt={whiteDress.alt}
                />
              </div>
              <div>
                <div css={thickLineStyle}>
                  <div></div>
                </div>
                <p css={mobileTextStyles}>
                  Award winning Australian soprano Sharon Kempton performs
                  worldwide in opera, recitals and concerts.
                </p>
                <p css={mobileTextStyles2}>
                  {" "}
                  She has recently appeared as the First Lady in the Magic
                  Flute, Gertrude in Hänsel und Gretel, and as Jungfer Marianna
                  Leitmetzerin in Der Rosenkavalier for the Hessen State Theatre
                  in Wiesbaden, Germany.{" "}
                </p>

                <Flex justify="flex-end" height="4em">
                  <Link
                    style={{
                      paddingTop: "1em",
                      color: "black",
                      fontWeight: "bold",
                      paddingRight: "3em",
                      textDecoration: "none",
                    }}
                    to="/bio"
                  >
                    Read More
                  </Link>
                </Flex>
              </div>
            </>
          )
          : (
            <>
              <section css={titleContainer}>
                <Flex
                  {...imageCenterStyle}
                  direction="column"
                  justify="space-between"
                >
                  <section
                    style={{
                      flex: 1,
                      display: "flex",
                      fontFamily: "lato",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ height: "max-content" }}>
                      <p css={blurbStyles}>
                        Award winning Australian soprano Sharon Kempton performs
                        worldwide in opera, recitals and concerts. She has
                        recently appeared as the First Lady in the Magic Flute,
                        Gertrude in Hänsel und Gretel, and as Jungfer Marianna
                        Leitmetzerin in Der Rosenkavalier for the Hessen State
                        Theatre in Wiesbaden, Germany.{" "}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          height: "4em",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            borderTop: "2px solid black",

                            width: "7em",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Link
                            style={{
                              paddingTop: "1em",
                              color: "black",
                              fontWeight: "bold",

                              textDecoration: "none",
                            }}
                            to="/bio"
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section css={fontsStyle}>
                    <h1 style={{ fontSize: "45px", fontWeight: "bold" }}>
                      Sharon Kempton
                    </h1>
                    <div css={lineStyle}></div>
                    <h2 style={{ fontSize: "35px", fontWeight: 300 }}>
                      Soprano
                    </h2>
                  </section>
                </Flex>
              </section>
              <section css={imageWrapper}>
                <div style={imageCenterStyle}>
                  <img
                    css={heroImageStyle}
                    src={whiteDress.img}
                    alt={whiteDress.alt}
                  />
                </div>
              </section>
              <div css={testingit}>Sharon Kempton</div>
              {" "}
            </>
          )}
      </article>
      <BioHero data={bio} />
      <AwardsHero data={awards} />
      <ReviewsHero data={reviews} />
      <PerformanceHero data={upcoming} />
      <Spacing height="6em" />
    </div>
  );
}
function Spacing({ height = "100%", width = "100%" }) {
  return <div style={{ width, height }}></div>;
}

export default Home;

function Flex({
  justify,
  children,
  align,
  direction,
  width = "100%",
  height = "100%",
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: justify,
        alignItems: align,
        flexDirection: direction,
        width,
        height,
      }}
    >
      {children}
    </div>
  );
}
