/** @jsxRuntime classic */
/** @jsx jsx */
import React from "react";

import { jsx } from "@emotion/react";

import LargeVersion from "../comps/contact/LargeVersion";
import SmallVersion from "../comps/contact/SmallVersion";
import useMedia from "../hooks/useMedia";
function ContactPage() {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const mobile = useMedia("(max-width: 850px)");

  if (mobile) {
    return <SmallVersion />;
  } else {
    return <LargeVersion />;
  }
}

export default ContactPage;
