/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";

import { contactPicture } from "../../assets/index";
import { PointRight } from "../../assets/svgs";

function LargeVersion() {
  const { img, alt } = contactPicture;
  const mainStyle = css`
    background: white;
    display: grid;
    grid-template-columns: 3fr 1.2fr;
    height: 100vh;
    @media (max-width: 1229px) {
      grid-template-columns: 3fr 1.5fr;
    }
    @media (max-width: 950px) {
      grid-template-columns: 3fr 1.7fr;
    }

    @media (max-width: 843px) {
      display: block;
      height: auto;
    }
  `;
  const textContainer = css`
    /* background: red; */
    padding: 3em 3em 0 0.6em;
    display: grid;
    grid-template-rows: 200px 1fr 1fr auto auto;
    grid-template-columns: auto 4fr 1fr;
    width: 70%;
    justify-self: flex-end;

    grid-template-areas:
      " .  .   .  "
      " . more .  "
      " . text .  "
      " .  .   .  "
      " . slidingtext svgslide   ";
    @media (max-width: 1229px) {
      padding: 3em 1em 0 0.6em;
    }
    @media (max-width: 1190px) {
      width: 80%;
      padding: 3em 0em 0 0.6em;
    }

    @media (max-width: 843px) {
      grid-template-columns: auto;
      grid-template-rows: auto;
      grid-template-areas:
        "slidingtext"
        "more"
        "text";
      width: auto;
      padding: 4em 0 0 0;
      height: 50vh;
    }
  `;
  const largerstyle = css`
    font-size: 3.6vmin;
    grid-area: more;
    max-width: 45%;
    font-weight: bold;
    align-self: center;
    @media (max-width: 1120px) {
      align-self: flex-end;
      max-width: 70%;
    }

    @media (max-width: 843px) {
      justify-self: center;
    }
  `;
  const smallstyle = css`
    font-size: 1.6vmin;
    grid-area: text;
    max-width: 50%;
    /* height: 50% */
    align-self: center;
    @media (max-width: 843px) {
      justify-self: center;
    }
  `;
  const stayInTouch = css`
    font-size: 8vmin;
    font-weight: 600;
    text-transform: uppercase;
    grid-area: slidingtext;
    color: rgb(20, 20, 20);
    align-self: flex-end;
    width: max-content;
    @media (max-width: 1120px) {
      font-size: 6vmin;
    }
    @media (max-width: 843px) {
      justify-self: center;
    }
    span {
      transform: none;
      display: block;
    }
    span:first-child {
      animation: contactAnim 2s ease-in-out both;
    }
    span:last-child {
      animation: contactAnim 2s ease-in-out both;
      animation-delay: 0.4s;
    }

    @keyframes contactAnim {
      from {
        transform: translateX(-1000%);
      }
    }
  `;
  const imageRight = css`
    position: relative;
    width: 100%;
    height: 100vh;
    justify-self: flex-end;
    grid-column: 2;
    display: grid;
    grid-template-rows: 80fr 20fr;
    overflow: hidden;
    img {
      display: block;
      max-width: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      visibility: visible;
      background: white;

      animation: slideAway 0.8s cubic-bezier(0.5, 0, 0.5, 1) forwards;
      animation-delay: 0.5s;
    }

    @keyframes slideAway {
      to {
        transform: translateY(-100%);
        visibility: hidden;
      }
    }

    @media (max-width: 843px) {
      max-width: 70%;
      margin: 0 auto;
    }
  `;
  const contactDeets = css`
    background-image: linear-gradient(180deg, #ff9f68, #ff9f68);
    width: 100%;
    height: 100%;
    padding: 1.3rem 1.3rem 0 1.3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 1000px) {
      justify-content: space-evenly;
    }
  `;
  const contacts = css`
    list-style: none;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    font-style: italic;
    margin: 0;
    padding: 0;
    li {
      cursor: pointer;
      -webkit-font-smoothing: subpixel-antialiased;
      transition: transform 0.25s ease-in;
      transform: translate3d(0, 0, 0);
      animation: contactSlider 1.3s cubic-bezier(0.5, 0, 0.5, 1) both;
      transform: none;
      opacity: 1;

      &:hover {
        text-decoration: underline;
        transition: transform 0.3s ease-in;
        transform: scale(1.1);
      }
    }
    @keyframes contactSlider {
      from {
        transform: translateX(20rem);
        opacity: 0;
      }
    }
  `;
  const contactBlurb = css`
    line-height: 1.5;
    animation: fadeSlideUp 1s linear;
    transform: none;
    opacity: 1;
    @keyframes fadeSlideUp {
      from {
        transform: translateY(60%);
        opacity: 0;
      }
    }
    @media (max-width: 1200px) {
      font-size: 1rem;
    }

    @media (max-width: 1000px) {
      line-height: 1.3;
    }
  `;
  return (
    <div css={mainStyle}>
      <div css={textContainer}>
        <div css={largerstyle}>For any questions or requests</div>
        <div css={smallstyle}>
          Sharon is based in Wiesbaden Germany. In addition to her singing
          career Sharon is also a professional Life and Performance Coach who
          can help guide and support singers navigating a performance career.
          <h2 style={{ margin: 0, paddingTop: "1em" }}>Email</h2>
          <div>
            <a
              style={{
                display: "block",
                color: "black",
                paddingTop: ".5em",
                cursor: "pointer",
              }}
              href="mailto:sekemptonsoprano@gmail.com"
            >
              sekemptonsoprano@gmail.com
            </a>
          </div>
        </div>

        <div css={stayInTouch}>
          <span>Let's get</span> <span>in Touch</span>
        </div>
        <PointRight />
      </div>
      <div css={imageRight}>
        <img src={img} alt={alt} />
        <div css={contactDeets}>
          <div css={contactBlurb}>
            You can contact Sharon via email and follow her on social media
            below. You can see the latest of Sharon's work in Performance
            Coaching for younger singers
          </div>
          <ul css={contacts}>
            <li>Email</li>
            <li>Facebook</li>
            <li>Instagram</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default LargeVersion;
