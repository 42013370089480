import ReviewOne from "../assets/review1.jpg";
import ReviewTwo from "../assets/8.jpg";
import ReviewThree from "../assets/6.jpg";
export const germanReviews = [
  {
    review:
      `Mit der Australischen Sopranistin Sharon Kempton, hatte man für die Operngala eine erfahrene
  und vielseitige Solistin verpflichtet, die in diesem Programm unterschiedlichste Partien zu
  bewältigen hatted dieses auch überzeugender Weise tat. Sharon Kempton verfügt über eine
  angenehm geschmeidig einschwingende Stimme, die sie technisch perfekt führt. So ließ sie die
  wehmütigen Zwischentöne in die Arie der Gräfin aus Figaros Hochzeit subtil deutlich werden,
  meisterte dann die Koloraturen in der Arie der Rosina aus dem Barbier von Sevilla (eigentlich
  keine Sopranarie dafür umso bewundernswerter) in atemberaubender Manier.`,
    author: "Frank Sydow",
    quote: "experienced and versatile",
    img: ReviewOne,
  },
  {
    review: `Sopranistin Sharon Kempton begeistert!
    Mit großer, sehr flexibel agierender Stimme machte sie den Konzertabend zu einem richtigen
    Opern-Erlebnis. Vom zartesten Piano bis zum großrahmigen Forte gestaltete sie die Arien der
    Gräfin Almaviva aus „Figaros Hochzeit“ sowie die der Fiordiligi aus „Cosi fan tutte“ ebenso
    professionell wie anrührend. In der Arie „Come scoglio“ sowie der Arie der Elena aus „I vespri
    siciliana“ bewies sie ihre Klasse zudem in umfangreichen Koloraturen.
    Die australische Sängerin ist keine Unbekannte in Wiesbaden. Von 2003 bis 2014 war sie
    Ensemblemitglied des Staatstheaters, hat in dieser Zeit 38 verschiedene Partien gesungen. Diese
    Professionalität und Erfahrung, dazu eine starke Bühnenpräsenz und die wandlungsfähige Stimme
    gaben dem Abend eine ganz besondere Klasse.`,
    author: `By Beke Heeren-Pradt
    Wiesbaden Kurier`,
    quote: "strong stage presence and a versatile voice",
    img: ReviewTwo,
  },
  {
    review:
      `Sharon Kempton beweist als Jungfer Marianne Leitmetzerin, dass sie alles in der Welt ist, nur
    keine Jungfer. Schrill und witzig, manchmal auch nachdenklich, macht sie aus dieser Rolle mit
    frischem, brillierendem Sopran und lustvollem Spiel eine feine Charakterstudie.`,
    author: "IOCO Kultur im Netz",
    quote: "Shrill and witty",
    img: ReviewThree,
  },
];

export const englishReviews = [
  {
    review:
      `Australian soprano Sharon Kempton, an experienced and versatile soloist..had to cope with the
  most diverse roles in this programme and did so convincingly. Sharon Kempton has a pleasantly
  supple voice, which she produces with a perfect technique. She subtly brought out the melancholy
  nuances in the Countess of Figaro&#39;s Wedding Aria, then mastered the coloraturas in the Aria of
  Rosina from the Barber of Seville (actually not a soprano aria, but all the more admirable) in a
  breathtaking manner.`,
    author: "Frank Sydow",
    quote: "experienced and versatile",
    img: ReviewOne,
  },
  {
    review:
      `With her large, very flexible voice she made the concert evening a real opera experience. From the
    most delicate piano to the large-frame forte, she performed the arias of Countess Almaviva from
    &quot;Figaro&#39;s Marriage&quot; and the Fiordiligi from &quot;Cosi fan tutte&quot; in a professional and touching manner. In
    the aria &quot;Come scoglio&quot; as well as the aria of Elena from &quot;I vespri siciliana&quot; she proved her class in
    extensive coloraturas.
    The Australian singer is no stranger to Wiesbaden. From 2003 to 2014 she was a member of the
    State Theatre ensemble, singing 38 different roles during this time. This professionalism and
    experience, along with a strong stage presence and a versatile voice, gave the evening a very
    special class.`,
    author: `By Beke Heeren-Pradt
    Wiesbaden Kurier`,
    quote: "strong stage presence and a versatile voice",
    img: ReviewTwo,
  },
  {
    review:
      `Sharon Kempton proves as spinster Marianne Leitmetzerin that she is everything in the world,
    except a spinster. Shrill and witty, sometimes even thoughtful, she turns this role into a fine
    character study with fresh, brilliant soprano and lustful playing.`,
    author: "IOCO Kultur im Netz",
    quote: "Shrill and witty",
    img: ReviewThree,
  },
];
