/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";

import entrance from "../../assets/awards_1.jpg";

const awardsDataArray = [
  "First Prize Winner, German Australian Opera Grant 2003",
  "First Prize Winner, German Operatic Award 2002",
  "Second Prize Winner, Herald Sun Aria 2000",
  "First Prize Winner, City of Warrnambool Aria 2000",
  "Finalist, Covent Garden Award 1999",
  "Finalist, Herald Sun Aria 1997",
  "Winner of the National Liederfest 1994",
];
function LargeVersion() {
  const mainStyles = css`
    display: grid;
    grid-template-columns: 5fr 3.5fr;
    font-family: "Prata", serif;
  `;
  return (
    <div css={mainStyles}>
      <Header />
      <Image />
    </div>
  );
}

function Header() {
  const textContainer = css`
    grid-column: 1;
    display: grid;
    height: 100vh;
    grid-template-rows: 2fr 1fr 2fr;
    padding-left: 128px;
  `;

  const headerStyles = css`
    align-self: flex-end;

    h1 {
      font-size: 3.3rem;
      margin: 0;
      max-width: max-content;
    }
    h3 {
      font-size: 4rem;
      margin: 0;
      max-width: max-content;
    }

    @media (max-width: 1279px) {
      h1 {
        font-size: 3rem;
      }
      h3 {
        font-size: 3.5rem;
      }
    }

    @media (max-width: 1225px) {
      h1 {
        font-size: 3rem;
      }
      h3 {
        font-size: 3rem;
      }
    }
    @media (max-width: 1034px) {
      h1 {
        font-size: 2.5rem;
      }
      h3 {
        font-size: 2.6rem;
      }
    }
  `;
  const subtitle = css`
    align-self: center;
  `;
  const awardsList = css`
    align-self: center;
    p {
    }

    @media (max-width: 1034px) {
      p {
        font-size: 0.9rem;
      }
    }
  `;
  return (
    <div css={textContainer}>
      <header css={headerStyles}>
        <h3>Sharon Kempton</h3>
        <h1>Awards</h1>
      </header>
      <div css={subtitle}>International Award Winning Soprano.</div>
      <div css={awardsList}>
        {awardsDataArray.map((text, i) => (
          <p key={i}>{text}</p>
        ))}
      </div>
    </div>
  );
}

function Image() {
  const containerStyles = css`
    grid-column: 2;

    display: grid;
    > * {
      grid-area: 1 / 1;
    }
  `;
  const imgstyles = css`
    /* display: block; */
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  `;
  return (
    <div data-test css={containerStyles}>
      <img
        css={imgstyles}
        alt="Sharon in a heroic pose on the stage"
        src={entrance}
      />
    </div>
  );
}

export default LargeVersion;
