/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useContext } from "react";

import { css, jsx } from "@emotion/react";
import { LanguageContext } from "../store";
import { BioPicture } from "../assets/index";

function Biography() {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const Bio = css`
    background: white;
    padding-bottom: 8em;
  `;
  return (
    <div css={Bio}>
      <TextBody />
    </div>
  );
}

function TextBody() {
  const { state } = useContext(LanguageContext);
  const mainstyles = css`
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;

    @media (max-width: 1080px) {
      max-width: 800px;
    }
  `;
  const textStyles = css`
    line-height: 1.7;
    font-size: 1.2rem;
    padding-bottom: 0.8em;
  `;
  const title = css`
    text-align: center;
    font-size: 3rem;
    padding-top: 3em;
    /* margin-top: 3em; */
    padding-bottom: 1em;
  `;

  const headerStyles = css`
    padding-top: 4em;
    display: grid;
    grid-template-columns: 1fr 1.2fr;

    @media (max-width: 794px) {
      max-width: 90%;
      margin: 0 auto;
    }
    @media (max-width: 760px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      justify-items: center;
    }
  `;
  const picStyles = css`
    max-width: 100%;
    height: auto;
    display: block;
    transition: transform 0.6s cubic-bezier(0.3, 0.5, 0, 1);
  `;
  const imageWrapper = css`
    position: relative;
    width: 400px;
    z-index: 1;

    &:before {
      position: absolute;
      content: "";
      top: 15px;
      left: 15px;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: #ff9f68;
      z-index: -1;
    }
    &:hover {
      img {
        transform: translate(-2%, -2%);
      }
    }

    @media (max-width: 790px) {
      max-width: 300px;
      width: auto;
    }
  `;
  const quotestyles = css`
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 50%;
    h3,
    h4 {
      margin: 0;
      color: #ff9f68;
    }
    h3 {
      font-size: 1.6rem;
      quotes: auto;
      font-style: italic;
    }

    h3::before {
      content: open-quote;
    }
    h3::after {
      content: close-quote;
    }

    @media (max-width: 1080px) {
      h3 {
        font-size: 1.4rem;
        line-height: 1.5;
        width: 80%;
        text-align: center;
      }
    }

    @media (max-width: 760px) {
      width: 100%;
      height: 14em;
    }
    @media (max-width: 707px) {
      h3 {
        font-size: 1.2rem;
        line-height: 1.5;
        width: 80%;
        text-align: center;
      }
    }
  `;
  const textWrapper = css`
    @media (max-width: 862px) {
      max-width: 700px;
      margin: 0 auto;
    }
    @media (max-width: 886px) {
      max-width: 600px;
      margin: 0 auto;
    }
    @media (max-width: 670px) {
      max-width: 500px;
      margin: 0 auto;
    }
    @media (max-width: 560px) {
      max-width: 400px;
      margin: 0 auto;
    }
    @media (max-width: 458px) {
      max-width: 80%;
      margin: 0 auto;
      p {
        word-wrap: break-word;
        font-size: 1rem;
      }
    }
  `;
  const { img, alt } = BioPicture;
  return (
    <div css={mainstyles}>
      <div css={headerStyles}>
        <div css={imageWrapper}>
          <img css={picStyles} src={img} alt={alt} />
        </div>
        <div css={quotestyles}>
          <SmallLine />
          <h3>Strong Stage Presence And A Versatile Voice</h3>
          <h4>-Wiesbaden Kurier</h4>
          <SmallLine />
        </div>
      </div>
      <h1 css={title}>About me</h1>
      <div css={textWrapper}>
        {state.biography.map((text, i) => (
          <p key={i} css={textStyles}>
            {text}
          </p>
        ))}
      </div>
    </div>
  );
}

function SmallLine() {
  const mainStyles = css`
    width: 50%;
    height: 1px;
    background: #ff9f68;
  `;
  return <div css={mainStyles}></div>;
}
export default Biography;
